
import {defineComponent, ref, reactive, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {UaProject, User} from "@/store/modules/ApiModule";
import roles from "@/core/data/roles";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "add-user-modal",
  components: {},
  props: {
    user: {
      type: Object as () => User,
      required: true
    }
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = reactive<User>({
      id: 0, name: "", email: "", password: "", role: "", token:""
    });
    const store = useStore();

    watch(props.user, (newValue, oldValue) => {
      Object.assign(formData, newValue);
      if(formRef.value){
        formRef.value.clearValidate();
      }
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "User name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          type: "email",
          message: "Must be a valid e-mail",
          trigger: "change",
        },
      ],
      role: [
        {
          required: true,
          message: "Role is required",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Password is required",
          trigger: "change",
        },
      ]
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          //loading.value = true;
          store.dispatch(Actions.POST_USER, formData).then(() => {
            store.dispatch(Actions.LOAD_USERS);
            Swal.fire({
              text: "User has been saved!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              if(formRef.value){
                formRef.value.resetFields();
              }
              hideModal(addUserModalRef.value);
            });
          });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addUserModalRef,
      roles
    };
  },
});
