import {Role} from "@/store/modules/ApiModule";

const roles: Array<Role> = [
    {
        id: "ADMIN",
        name: "Admin"
    },
    {
        id: "USER",
        name: "User"
    }
];

export default roles;
